import React from "react";
import I18n from "i18n-react";
import "./Information.css";

export default function Information(props) {
    return (
        <div id="information">
            <p>
                {I18n.translate(props.message)}
            </p>
        </div>
    );
}
