import React from "react";
import I18n from "i18n-react";
import "./Typing.css";

export default function Typing() {
    return (
        <div id="typing">
            <div id="wave">
                <span className="dot"></span>
                <span className="dot"></span>
                <span className="dot"></span>
            </div>
            <div id="typing-text">
                { I18n.translate("is_tipping_message") }
            </div>
        </div>
    );
}
